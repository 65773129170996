import React, {useState} from 'react';
import {Box, Heading, Text, Styled, jsx} from 'theme-ui';
import {useSpring, animated} from 'react-spring';
import { Image } from 'react-datocms';
import Link from 'next/link';
import styled from '@emotion/styled';
import BsRoundButton from './BsRoundButton';

const BsBox = props => {
  const {naslov, kratakOpis, bojaPozadine, slika, link, customUrlLink} = props.data;

  const {boxHeight, mb, mr, ml, light, textOnImage} = props;

  const StyledLink = styled(Link)`
    color: inherit;
    text-decoration: none;
    width: 100%;
    display: flex;
  `;

  const [hovered, setHovered] = useState(false);
  const springBs = useSpring({opacity: 1, from: {opacity: 0}});
  const AnimatedBox = animated(Box);

  const handleGatsbyImage = slika => {
    return <Image height="100%" width="100%" layout="fill" objectFit='cover' data={slika.responsiveImage} />;
  };

  return (
    <StyledLink
      sx={{
        height: boxHeight,
        mb: mb || [3, 0],
        mr,
        ml,
        pointerEvents: link ? 'auto' : 'none',
      }}
      className="boxie"
      passHref
      href={`/${link}` || '/'}>
      <Box as="a" sx={{
        width: '100%',
        height: '100%'
      }}>
      <AnimatedBox
        sx={{
          height: '100%',
          minHeight: [250, 'auto'],
          bg: !slika && (bojaPozadine || []).hex,
          ':hover': {
            cursor: 'pointer',
          },
          '& > span': {
            transform: 'translateX(-0.5rem)',
            bg: 'orange',
          },
          ':hover > span': {
            transform: 'translateX(0rem)',
            transition: '0.25s all ease-in-out',
            bg: '#fff',
          },
          ':hover .image-overlay': {
            background: 'rgba(0,0,0,0.4)',
            transition: '0.25s all ease-in-out',
          },
          ':hover .box-heading': {
            transition: '0.35s all ease-in-out',
            // transform: 'translateY(5px)'
          },
          ':hover .box-description': {
            transition: '0.35s all ease-in-out',
            transform: 'translateY(10px)',
          },
          '& > span > svg > path': {
            fill: '#fff',
          },
          ':hover > span > svg > path': {
            fill: 'orange',
          },
          '& img': {
            transition: '0.75s all ease-in-out!important',
            transform: 'scale(1)',
          },
          ':hover img': {
            transform: 'scale(1.05)',
            transition:
              '0.5s opacity ease-in-out, 0.5s transform ease-in-out!important',
          },
          borderRadius: ['sm', 'md'],
          p: [4],
          pr: [3, 3, 3, 6],
          position: 'relative',
          overflow: 'hidden',
          width: '100%',
          bg: !light ? 'primaryGreenLight' : 'white',
          borderRadius: 10,
          boxShadow: light ? 'xl' : 'lg',
          transition:
            '0.5s all ease-in-out, 0.5s transform ease-in-out!important',
          '&:hover': {
            boxShadow: 'xxl',
          },
        }}>
        {/* <BsRoundButton></BsRoundButton> */}
        {!textOnImage && <Box
          sx={{
            position: 'absolute',
            height: textOnImage ? 'auto' : '64px',
            width: '100%',
            bottom: textOnImage ? 3 : 0,
            left: 0,
            px: [24, 16, 24],
            display: 'flex',
            alignItems: !textOnImage ? 'center' : 'start',
            flexDirection: textOnImage ? 'column' : 'row',
            background: !light ? 'primaryGreenLight' : 'white',

            zIndex: 2,
          }}>
          <Heading
            className="box-heading"
            sx={{
              transition: '0.25s all ease-in-out',
              fontSize: textOnImage ? 7 : [4],
              fontWeight: light ? 600 : 400,
              letterSpacing: '0.2px',
              color: !light ? 'white' : 'primaryGreen',
            }}
            as="h3">
            {naslov || ''}
          </Heading>
          {textOnImage ? (
            <Box sx={{mt: 2, color: 'white'}}>
              <Styled.h2
                sx={{
                  transition: '0.25s all ease-in-out',
                  color: 'white',
                  fontSize: [2, 2, 2, 'auto'],
                }}
                className="box-description"
                as="span">
                {kratakOpis}
              </Styled.h2>
            </Box>
          ) : null}
        </Box>}

        <Box
          sx={{
            width: '100%',
            height: textOnImage ? '100%' : 'calc(100% - 64px)',
            position: 'absolute',
            left: '0',
            top: '0',
            zIndex: 1,
            borderRadius: '10px 10px 0 0',
            overflow: 'hidden',
          }}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: -1,
              '& > div': {
                height: '100%',
                width: '100%',
              },
            }}>
            <Box
              display={props.hideOverlay ? 'none' : 'block'}
              className="image-overlay"
              sx={{
                transition: '0.25s all ease-in-out',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1,
                '& > div': {
                  height: '100%',
                },
                background: 'rgba(0,0,0,0.2)',
              }}
            />
            {handleGatsbyImage(slika)}
          </Box>
        </Box>
      </AnimatedBox>
      </Box>
    </StyledLink>
  );
};

BsBox.defaultProps = {
  boxHeight: '100%',
  mb: 0,
  ml: 0,
  mr: 0,
  textOnImage: false,
};

export default BsBox;
