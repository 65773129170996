import {Box, Heading, Button, useThemeUI} from 'theme-ui';
import {useSpring, animated} from 'react-spring';

// import ArrowIcon from '!svg-react-loader!../../assets/arrow.inline.svg?name=ArrowIcon';

const BsRoundButton = props => {
  const AnimatedBox = animated(Box);
  const context = useThemeUI();
  const {theme} = context;

  return (
    <>
      <AnimatedBox
        as="span"
        sx={{
          position: 'absolute',
          bottom: props.bottom,
          right: props.right,
          top: props.top,
          p: props.padding,
          transition: '0.75s all ease-in-out',
          display: ['none', 'flex'],
          borderRadius: props.br,
          bg: 'white',
          zIndex: 2,
        }}>
        {/* <img src={Icon} alt="Arrow Button"/> */}

        {/* <ArrowIcon {...props} /> */}
      </AnimatedBox>
    </>
  );
};

BsRoundButton.defaultProps = {
  // bg: 'white'
  bottom: '74px',
  right: '1rem',
  top: 'auto',
  padding: 2,
  br: '1rem',
};

export default BsRoundButton;
