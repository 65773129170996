// import styled from '@emotion/styled';
// import Link from 'next/link';
import {Box, Flex} from 'theme-ui';
import BsBoxHome from '@/components/BsBox/BsBoxHome';
// import SideMenu from '@/components/SideMenu';
import {BsBoxRow} from '@/components/BsBoxRow/BsBoxRow';

const Hero = props => {
  // const StyledLink = styled(Link)`
  //   text-decoration: none;
  //   color: inherit;
  // `;

  const {kategorijaHighlight, products, redoviKategorija} = props;

  // const handleEmptyLinks = link => {
  //   if (link.length === 0) return '/';
  //   return link;
  // };

  return (
    <Box
      className="hero2ss"
      sx={{
        position: 'relative',
        zIndex: 1,
        mt: [0],
        pt: [0],
        px: [2, 4, 6],
      }}>
      <Flex
        sx={{
          position: 'relative',
          flexDirection: ['column', 'row', 'row'],
          flexWrap: ['wrap', 'nowrap'],
          gap: [0,3],
        }}>
        <Box
          mr={0}
          mt={[1, 3]}
          sx={{
            width: ['100%', '25%'],
          }}>
          <BsBoxHome
            data={{
              naslov: kategorijaHighlight.imeKategorije,
              slika: kategorijaHighlight.slika,
              link: kategorijaHighlight.slug,
            }}
          />
          {/* <SideMenu products={products} kategorije={kategorije}/> */}
        </Box>

        {/* <Box color="#fff" width={[1, 1, 1, 2/3]} height={[250, "auto"]} mb={[1, 0]} mr={[0, 2]}>
              <BsBox headlineFontSize="2.5rem" data={}/>
          </Box> */}

        <Box
          sx={{
            width: ['100%', '75%',],
            display: ['flex', 'flex'],
            flexDirection: ['row', 'column'],
            gap: 1,
          }}>
          {BsBoxRow(redoviKategorija)}
        </Box>

        {/* <Flex color="#fff" flexDirection="column" width={[1, 1, 1, 1/2, 1/2]}>
          <Box bg="props.theme.colors.white" flex="1 1 auto" width={1} height={[200, "auto"]} mb={[1, 2]}>
              <BsBox headlineFontSize={[5,5,5,5,4]} data={}/>
          </Box>
        </Flex> */}
        {/* TODO: Hover Overlay */}
        {/* <Box
          width="80.4%"
          height="100%"
          sx={{
            position: 'absolute',
            right: 0,
            zIndex: 2,
            bg: 'darkGreen',
            borderRadius: 'md'
          }}
        >
        </Box> */}
      </Flex>
    </Box>
  );
};

export default Hero;
