import {Box, Flex} from 'theme-ui';
import BsBoxHome from '../BsBox/BsBoxHome';

const handleBlokovi = red =>
  red.blokovi.map(y => (
    <Box
      key={y.imeKategorije}
      mx={[0, 0]}
      sx={{
        width: ['100%', `${100 / red.blokovi.length}%`],
        maxHeight: ['50vw', '75vw'],
        height: ['50vw', '244px'],
        borderRadius: 5,
      }}>
      <BsBoxHome
        data={{
          naslov: y.imeKategorije,
          slika: y.slika,
          link: y.slug,
        }}
        //   headlineFontSize={[5,5,5,2]}
      />
    </Box>
  ));

export const BsBoxRow = red =>
  red.map((x,index) => {
    return (
      <Flex
        key={x.id}
        mt={[0, 3]}
        color="white"
        sx={{
          gap: 2,
          flexWrap: ['wrap', 'nowrap'],
          width: '100%',
          flexDirection: 'row',
          '& div:first-of-type': {
            marginLeft: '0rem',
          },
          '& div:last-of-type': {
            marginRight: '0rem',
          },
        }}>
        {handleBlokovi(x)}
      </Flex>
    );
  });
