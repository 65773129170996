import {Box, Text,Themed} from 'theme-ui';
import BsBoxHome from '@/components/BsBox/BsBoxHome';

const HighlightsGrid = props => {
  const {highlight = []} = props;

  const [xl, reg] = highlight;

  const niz = highlight.slice(1,highlight.length)

  const handleXlRow = prop => (
    <Box
      sx={{
        display: 'flex',
        flexWrap: ['wrap', 'nowrap'],
        width: '100%',
      }}>
      <Box
        sx={{
          width: ['100%', '50%'],
          mr: [0, 4],
          mb: [3, 0],
        }}>
        {props.length > 0 && prop[0] && <BsBoxHome textOnImage data={prop[0]} />}
      </Box>
      <Box
        sx={{
          width: ['100%', '50%'],
          display: 'flex',
          flexDirection: 'column',
        }}>
        {props.length > 0 && prop[1] && <BsBoxHome
          boxHeight={['auto', '320px']}
          light
          mb={[0, '24px']}
          data={prop[1]}
        />}
        {props.length > 0 && prop[2] && <BsBoxHome boxHeight={['auto', '320px']} light data={prop[2]} />}
      </Box>
    </Box>
  );

  const handleRegRow = props =>
    props.map((x,i)=> (
      <BsBoxHome
        key={`rowKey${i}`}
        mr={[0, '12px']}
        ml={[0, '12px']}
        boxHeight={['auto', '320px']}
        mb={4}
        light
        data={x}
      />
    ));

  return (
    <Box
      sx={{
        px: [2, 0],
        mb: 6,
      }}>
      <Box
        sx={{
          display: 'flex',
          mb: [0, 4],
        }}>
        {handleXlRow(xl.highlightBlocks)}
      </Box>
      {niz.map((x,i) => <Box key={i}>
        {x?.naslov && <Text as="h1" sx={{ mb: [2, 2], fontFamily: 'heading', color: 'primaryGreen', fontSize: [6, 7] }}>{x?.naslov}</Text>}
        <Box
        sx={{
          display: 'flex',
          // flexDirection: 'column',
          flexWrap: ['wrap', 'nowrap'],
          mx: [0, '-12px'],
        }}>
        {handleRegRow(x.blokovi)}
      </Box></Box>)}
    </Box>
  );
};

export default HighlightsGrid;
