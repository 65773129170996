import { getAllListes, getFavicon, getPageHome } from 'lib/api'


import { Suspense, useContext, useEffect } from 'react';
import { Box, Text, Styled, Heading, } from 'theme-ui';
import BsBox from '@/components/BsBox/BsBox';

import { renderMetaTags } from 'react-datocms';
import Head from 'next/head';

import Hero from '@/components/Hero/hero';
// import ValueStrap from '../components/ValueStrap';
// import ProductHighlight from '../components/ProductHighlight';
// import ArticleItem from '../components/ArticleItem';

//TO DOOOO NEXTJS SEO
// import SEO from '@/components/SEO/index';

import HighlightsGrid from '@/components/HighlightsGrid';
import CartContext from '@/components/Contexts/CartContext';

import ProductCarousel from '@/components/ProductCarousel';
import dynamic from 'next/dynamic';
// const EmblaCarousel = dynamic(() => import('@/components/ProductCarousel'))

// import { EmblaCarouselSlider } from '@/components/SliderCarousel';
// const EmblaCarouselSlider = dynamic(() => import('@/components/SliderCarousel'))

import { getSalesChannelToken } from '@commercelayer/js-auth';
import CommerceLayer from '@commercelayer/sdk/lib/cjs/commercelayer';
import { handleProductListing } from '@/components/handleProductListing';

export default function Home(props) {
  const {
    setCartCheckoutReady
  } = useContext(CartContext);

  useEffect(() => {
    setCartCheckoutReady(false)
  }, [])

  const {
    kategorijaHighlight,
    redoviKategorija = [],
    highlight = [],
    slider = [],
  } = props.pageHome;


  const listaIzdvojeni = props.fullLists.filter(x => x.id === "69194721")
  const listaNovo = props.fullLists.filter(x => x.id === "68693538")
  const listaAkcija = props.fullLists.filter(x => x.id === "69194715")

  const benefitBoxes = [
    {
      name: 'Preko 1500 proizvoda',
    },
    {
      name: '34 godine na usluzi',
    },
    {
      name: 'Dostava na adresu',
    },
  ];

  const handleBenefitBoxes = data =>
    data.map((x, index) => {
      return (
        <Box
          key={x.name}
          sx={{
            width: ['100%', `${100 / data.length}%`],
            mb: [2, 0, 0],
            display: 'flex',
            alignItems: 'center',
          }}>
          <Box
            sx={{
              border: '1px solid #eee',
              borderRadius: '10px',
              height: '100%',
              width: '100%',
              px: 3,
              py: [4, 4, 5],
              display: 'flex',
              alignSelf: 'center',
            }}>
            <Box
              sx={{
                flexShrink: 0,
                bg: 'primaryOrange',
                width: [40, 40, 40],
                height: [40, 40, 40],
                borderRadius: '50%',
                mr: 3,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontSize: [3],
              }}
            >{index + 1}</Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}>
              <Text
                as="p"
                sx={{
                  fontSize: 5,
                  lineHeight: 1,
                  color: 'text',
                  fontFamily: 'heading',
                  fontWeight: 'bold',
                  mb: [0, 1],
                }}>
                {x.name}
              </Text>
            </Box>
          </Box>
        </Box>
      );
    });

  const schemaData =
  {
    "@context": "http://www.schema.org",
    "@type": "LocalBusiness",
    "name": "BioŠpajz", //$BUSINESS_NAME
    "description": "Bio Špajz postoji od 1986. godine i njegova misija je da populariše zdraviji način života kroz odgovarajuću ishranu, ali i zdrav odnos prema telu i duhu.", //$META_DESCRIPTION
    "url": "https://www.biospajz.rs/", //$WEBSITE_URL
    "logo": "https://www.datocms-assets.com/17050/1646220173-biospajz-logo-zeleni-jpeg.jpg", //$WEBSITE_LOGO_URL - you should provide URL of your logo from your website
    "contactPoint": {
      "@type": "ContactPoint",
      "contactType": "Customer Service",
      "email": "kutijica@biospajz.rs",
      "url": "https://www.biospajz.rs/kontakt" //$CONTACT_US_PAGE_URL
    },
    "department": [
      {
        "@type": "LocalBusiness",
        "name": "Bio Špajz BIG Fashion TC",//$SHOP1_NAME
        "image": "https://www.datocms-assets.com/17050/1583434782-bio-spajz-big-fashion.jpg",//$ABSOLUTE_URL_OF_SHOP1_PHOTO
        "telephone": "+381 65 8146 181",//$SHOP1_PHONE
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Beograd", //$CITY, $COUNTRY
          "streetAddress": "lokal 43v, BIG FASHION TC, Višnjička 84" //$STREET_AND_NUMBER
        },
        "openingHours": [
          "Mo-Su 10:00-22:00"
        ]
      },
      {
        "@type": "LocalBusiness",
        "name": "Bio Špajz Cvetko", //$SHOP2_NAME
        "image": "https://www.datocms-assets.com/17050/1582664131-biospajz-prodavnica-cvetkovapijaca-unutra-1.jpg", //$ABSOLUTE_URL_OF_SHOP2_PHOTO
        "telephone": "+381 11 2430-689", //$SHOP2_PHONE
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Beograd", //$CITY, $COUNTRY
          "streetAddress": "lokal 12, Cvetkova pijaca, Bulevar Kralja Aleksandra 297" //$STREET_AND_NUMBER
        },
        "openingHours": [
          "Tu-Fr 08:00-18:00",
          "Sa-Su 08:00-16:00"
        ]
      },
      {
        "@type": "LocalBusiness",
        "name": "Bio Špajz Kalenić", //$SHOP3_NAME
        "image": "https://www.datocms-assets.com/17050/1583435967-screenshot-2020-03-05-at-20-18-27.png", //$ABSOLUTE_URL_OF_SHOP3_PHOTO
        "telephone": "+ 381 11 24 333 13", //$SHOP3_PHONE
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Beograd", //$CITY, $COUNTRY
          "streetAddress": "lokal 34, Kalenić pijaca, Njegoševa bb," //$STREET_AND_NUMBER
        },
        "openingHours": [
          "Mo 08:00-14:00",
          "Tu-Fr 08:00-17:00",
          "Sa-Su 07:00-16:00"
        ]
      },
      {
        "@type": "LocalBusiness",
        "name": "Bio Špajz Blok 44", //$SHOP4_NAME
        "image": "https://www.datocms-assets.com/17050/1622775083-blok44-bio-spajz-ulaz.jpg", //$ABSOLUTE_URL_OF_SHOP4_PHOTO
        "telephone": "+381 64 641 95 32", //$SHOP4_PHONE
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Beograd", //$CITY, $COUNTRY
          "streetAddress": "lokal 38, pijaca Blok 44, Jurija Gagarina bb" //$STREET_AND_NUMBER
        },
        "openingHours": [
          "Tu-Fr 08:00-15:00",
          "Sa-Su 07:30-15:00"
        ]
      },
      {
        "@type": "LocalBusiness",
        "name": "Bio Špajz Merkator", //$SHOP5_NAME
        "image": "https://www.datocms-assets.com/17050/1585135311-img3011.jpg", //$ABSOLUTE_URL_OF_SHOP3_PHOTO
        "telephone": "+381 64 64 12 722",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Beograd", //$CITY, $COUNTRY
          "streetAddress": "pijaca Stari Merkator, Palmira Toljatija 5" //$STREET_AND_NUMBER
        },
        "openingHours": [
          "Tu-Fr 08:00-15:00",
          "Sa-Su 07:30-15:00"
        ]
      },
      {
        "@type": "LocalBusiness",
        "name": "Bio Špajz Zemun", //$SHOP5_NAME
        "image": "https://www.datocms-assets.com/17050/1585135311-img3011.jpg", //$ABSOLUTE_URL_OF_SHOP3_PHOTO
        "telephone": "+381 64 64 12 722",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Beograd", //$CITY, $COUNTRY
          "streetAddress": "Glavna 24, Zemun" //$STREET_AND_NUMBER
        },
        "openingHours": [
          "Mo-Sa 08:00-20:00"
        ]
      }
    ]
  }

  return (
    <>
      <Head>
        {renderMetaTags([...props.pageHome._seoMetaTags, ...props.site.faviconMetaTags])}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
      </Head>

      <Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            px: [2, 3, 6],
            pt: 5,
            '& > div': {
              px: [0, 2],
            },
            '& > div:first-of-type': {
              pl: 0,
            },
            '& > div:last-of-type': {
              pr: 0,
            },
          }}>
          {handleBenefitBoxes(benefitBoxes)}
        </Box>
        <Box
          sx={{
            px: [4, 5, 6],
            mb: [0, 0],
          }}>
          <Heading
          as={"h1"}
            sx={{
              mb: [2, 0],
              pt: [2,5],
              fontSize: [6, 54],
              fontWeight: 700,
              lineHeight: [1, 'inherit'],
            }}
          >
            Dobrodošli u
            <Box
              as="span"
              sx={{
                color: 'primaryOrange',
              }}>
              {' '}
              Bio Špajz
              {' '}
            </Box>
            zdrava hrana.

          </Heading>
        </Box>

        <Box
          sx={{
            position: 'relative',
            mb: [60, 160],
          }}>
          <Box
            sx={{
              bg: ['none', 'primaryGreen'],
              position: 'absolute',
              width: '100%',
              height: ['97%', '100%'],
              bottom: ['3%', 0],
              left: 0,
              mb: -80,
            }}
          />
          <Hero
            kategorije
            products
            kategorijaHighlight={kategorijaHighlight}
            redoviKategorija={redoviKategorija}
          />
        </Box>

        <Box sx={{
          mb: 5,
        }}><Box
          sx={{
            px: [4, 5, 6],
            mb: [0, 3],
          }}>
            <Text as="h1" sx={{ mb: [2, 0], fontFamily: 'heading', color: 'primaryGreen', fontSize: [6, 7] }}>
              Na akciji
            </Text>
          </Box>
          <Suspense fallback={null}>
            <ProductCarousel sliderClass="listaAkcija" array={listaAkcija[0].proizvodi} />
          </Suspense>
        </Box>

        <Box sx={{
          mb: 5,
        }}><Box
          sx={{
            px: [4, 5, 6],
            mb: [0, 3],
          }}>
            <Text as="h1" sx={{ mb: [2, 0], fontFamily: 'heading', color: 'primaryGreen', fontSize: [6, 7] }}>
              Najnoviji proizvodi
            </Text>
          </Box>
          <Suspense fallback={null}>
            <ProductCarousel sliderClass="listaNovo" array={listaNovo[0].proizvodi} />
          </Suspense>
        </Box>

        <Suspense fallback={null}>
          <Box className="lovelykinoaslider"
            sx={{
              pb: 6,
              px: [3,5],
            }}>
            <Box className=""
              sx={{
                display: 'flex',
                gap: 2,
                flexWrap: 'wrap',
                pb: 3,
                // overflowY: 'scroll',
                scrollSnapType: 'x mandatory',
                scrollBehavior: 'smooth',
                // WebkitOverflowScrolling: 'touch',
                '& .amaranth_slider': {
                  position: 'relative',
                  display: 'flex',
                  flex: ['0 0 100%', '0 0 49%'],
                  height: ['60vw', '20vw'],
                  bg: 'gray',
                  // mx: 1,
                  // mb: 1,
                  borderRadius: '1rem',
                  overflow: 'hidden',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  boxShadow: 'xl',
                  transition: 'box-shadow 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: 'xxl',
                  }
                }
              }}>
              {slider.map((x, i) => {
                return <div key={`waf${i}`} className="amaranth_slider">
                  <BsBox
                  textOnImage
                    data={{
                      naslov: x.headlineTitle,
                      bojaPozadine: "#555",
                      slika: x.media,
                      customUrlLink: x.link,
                      link: x.customUrlLink,
                    }}

                  />
                </div>
              })}
            </Box>
          </Box>

          {/* <EmblaCarouselSlider array={slider} /> */}
        </Suspense>

        <Box>
          <Box
            sx={{
              px: [4, 5, 6],
              mb: [0, 3],
            }}>
          </Box>
          <Box
            sx={{
              px: [1, 5, 6],
              mb: [0, 4, 4],
            }}>
            <HighlightsGrid highlight={highlight} />
          </Box>
        </Box>
      </Box>

      <Box sx={{
        mb: 5,
      }}><Box
        sx={{
          px: [4, 5, 6],
          mb: [0, 3],
        }}>
          <Text as="h1" sx={{ mb: [2, 0], fontFamily: 'heading', color: 'primaryGreen', fontSize: [6, 7] }}>
            Izdvajamo omiljene proizvode
          </Text>
        </Box>
        <Suspense fallback={null}>
          <ProductCarousel sliderClass="listaIzdvojeni" array={listaIzdvojeni[0].proizvodi} />
        </Suspense>
      </Box>
    </>
  )
}


export async function getStaticProps({ locale, preview = false }) {
  const pageHome = (await getPageHome(locale, preview)) || []
  let allListes = (await getAllListes(locale, preview)) || []
  const site = (await getFavicon(locale, preview)) || []

  const token = await getSalesChannelToken({
    clientId: 'M4MLTW_AwplUTNPHNsDfebvplZoPa-QmEMz8OUkJ14o',
    endpoint: 'https://bio-spajz.commercelayer.io',
    scope: 'market:4848'
  })

  const cl = CommerceLayer({
    organization: 'bio-spajz',
    accessToken: token.accessToken
  })

  // Get listed Product SKUs
  let skuList = []
  const a = allListes.map(x => {
    const a = x.proizvodi.map(y => {
      skuList.push(y.sku)
      return y.sku
    })
    return [...a]
  })

  let proizvodCL = await cl.skus.list({ pageSize: 24, pageNumber: 1, include: ['prices', 'stock_items'], filters: { code_in: skuList } })
  const pageCount = proizvodCL.meta.pageCount

  for (let i = 2; i <= pageCount; i++) {
    let xyz = await cl.skus.list({ pageSize: 24, pageNumber: i, include: ['prices', 'stock_items'], filters: { code_in: skuList } })
    proizvodCL.push(...xyz)
  }

  let fullLists = []

  // Merging DatoCMS product content, with CommerceLayer Price & Stocks data
  await allListes.map(x => {

    const proizvodi = x.proizvodi.map(y => {
      const proizvod = proizvodCL.find(z => z.code == y.sku)
      return {
        ...y,
        prices: proizvod?.prices[0] || {},
        stock_items: proizvod?.stock_items[0] || {},
      }

    })
    fullLists.push({
      id: x.id,
      proizvodi,
    })
    return {
      ...x,
      ...a,
    }
  })

  return {
    props: {
      pageHome,
      allListes,
      fullLists,
      site,
    },
  }
}
