import { useState } from 'react';
import { Box, Heading, Text, Styled, Themed, jsx } from 'theme-ui';
import { useSpring, animated } from 'react-spring';
import { Image } from 'react-datocms';
import Link from 'next/link';
import {useRouter} from 'next/router';
import styled from '@emotion/styled';
import BsRoundButton from './BsRoundButton';

const BsBoxHome = props => {
  const { naslov, kratakOpis, bojaPozadine, slika, link, hideOverlay } = props.data;

  const { boxHeight, mb, mr, ml, light, textOnImage } = props;

  const router = useRouter()

  const StyledLink = styled(Link)`
    color: inherit;
    text-decoration: none;
    width: 100%;
    display: flex;
  `;


  const [hovered, setHovered] = useState(false);
  const springBs = useSpring({ opacity: 1, from: { opacity: 0 } });
  const AnimatedBox = animated(Box);

  const handleGatsbyImage = slika => {
    if (slika && slika.responsiveImage) return <Image alt={naslov} layout="fill" style={{width: '100%', height: '100%'}} objectFit="cover" data={slika.responsiveImage} />;
  };

  return (
    <Box
      sx={{
        height: boxHeight,
        minHeight: ['none', 'none'],
        mb: mb || [1, 3, 0],
        mr,
        ml,
        color: 'inherit',
        textDecoration: 'none',
        width: '100%',
        display: 'flex',
      }}
      className="boxie"
      onClick={(e) => {
      e.preventDefault()
      router.push(`/${link}` || '/')
}}>
  <AnimatedBox
    sx={{
      // height: '100%',
      // maxHeight: [120, 'none'],
      minHeight: ['none', 250, 'none'],
      bg: !slika && (bojaPozadine || []).hex,
      bg: !light ? 'primaryGreenLight' : 'white',
      ':hover': {
        cursor: 'pointer',
      },
      '& > span': {
        transform: 'translateX(-0.5rem)',
        bg: 'orange',
      },
      ':hover > span': {
        transform: 'translateX(0rem)',
        transition: '0.25s all ease-in-out',
        bg: '#fff',
      },
      ':hover .image-overlay': {
        background: 'rgba(0,0,0,0.4)',
        transition: '0.25s all ease-in-out',
      },
      ':hover .box-heading': {
        transition: '0.35s all ease-in-out',
        // transform: 'translateY(5px)'
      },
      ':hover .box-description': {
        transition: '0.35s all ease-in-out',
        transform: 'translateY(10px)',
      },
      '& > span > svg > path': {
        fill: '#fff',
      },
      ':hover > span > svg > path': {
        fill: 'orange',
      },
      '& img': {
        transition: '0.75s all ease-in-out!important',
        transform: 'scale(1)',
      },
      ':hover img': {
        transform: 'scale(1.05)',
        transition:
          '0.5s opacity ease-in-out, 0.5s transform ease-in-out!important',
      },
      borderRadius: 10,
      p: [2, 4],
      pr: [2, 3, 3, 6],
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      boxShadow: light ? 'xl' : 'lg',
      transition:
        '0.5s all ease-in-out, 0.5s transform ease-in-out!important',
      display: ['flex', 'block'],
      alignItems: ['center', 'auto'],
      flexDirection: ['column-reverse', 'row-reverse'],
      '&:hover': {
        boxShadow: 'xxl',
      },
    }}>
    {/* <BsRoundButton></BsRoundButton> */}
    <Box
      sx={{
        position: ['inherit', 'absolute'],
        height: ['auto', textOnImage ? 'auto' : '64px'],
        width: '100%',
        bottom: [0, textOnImage ? 3 : 0],
        left: 0,
        pt: [3,0],
        pb: [1,0],
        px: [2, 16, 24],
        display: ['flex'],
        alignItems: [!textOnImage ? 'center' : 'start'],
        flexDirection: [
          textOnImage ? 'column' : 'row-reverse',
          textOnImage ? 'column' : 'row',
        ],
        background: !light ? 'primaryGreenLight' : 'white',
        textAlign: ['left', 'auto'],
        justifyContent: ['flex-end', 'flex-start'],
        zIndex: 2,
      }}>
      <Heading
        className="box-heading"
        sx={{
          transition: '0.25s all ease-in-out',
          fontSize: [4, textOnImage ? 7 : [4]],
          fontWeight: light ? 600 : 400,
          letterSpacing: '0.2px',
          color: !light ? 'white' : 'primaryGreen',
        }}
        as="h3">
        {naslov || ''}
      </Heading>
      {textOnImage ? (
        <Box sx={{ mt: [0,2], color: 'white' }}>
          <Styled.h2
            sx={{
              transition: '0.25s all ease-in-out',
              color: 'white',
              fontSize: [2, 2, 2, 'auto'],
            }}
            className="box-description"
            as="span">
            {kratakOpis}
          </Styled.h2>
        </Box>
      ) : null}
    </Box>

    <Box
      sx={{
        width: '100%',
        height: [300, textOnImage ? '100%' : 'calc(100% - 64px)'],
        position: ['relative', 'absolute'],
        left: '0',
        top: '0',
        zIndex: 1,
        borderRadius: [10, '10px 10px 0 0'],
        overflow: 'hidden',
      }}>
      <Box
        sx={{
          width: '100%',
          height: ['100%'],
          position: ['relative', 'absolute'],
          top: 0,
          left: 0,
          zIndex: -1,
          // maxHeight: [120, 'none'],
        }}>
        <Box
          display={hideOverlay ? 'none' : 'block'}
          className="image-overlay"
          sx={{
            transition: '0.25s all ease-in-out',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
            // minHeight: [100, 'none'],
            '& > div': {
              height: '100%',
            },
            background: 'rgba(0,0,0,0.2)',
          }}
        />
        {handleGatsbyImage(slika)}
      </Box>
    </Box>
  </AnimatedBox>
    </Box >
  );
};

BsBoxHome.defaultProps = {
  boxHeight: '100%',
  mb: 0,
  ml: 0,
  mr: 0,
  textOnImage: false,
};

export default BsBoxHome;
